import React, { Component } from "react";

class Wishlist extends Component {
  handleItemClick = item => {
    this.props.itemClick && this.props.itemClick(item);
  };

  handleAddItem = () => {
    this.props.addItem && this.props.addItem();
  };

  render() {
    const itemKeys = this.props.list ? Object.keys(this.props.list) : [];
    return (
      <ul className="list-group clickable-group">
        {this.props.list &&
          itemKeys.map(key => {
            const item = this.props.list[key];
            const className =
              key === this.props.activeKey
                ? "list-group-item list-group-item-info"
                : "list-group-item list-group-item-action";
            return (
              <li
                className={className}
                key={key}
                onClick={() => this.handleItemClick(key)}
              >
                {item.title}
              </li>
            );
          })}
        {!this.props.loading && this.props.showAddItem && (
          <li
            className="list-group-item list-group-item-action"
            key="addOne"
            onClick={this.handleAddItem}
          >
            Add an item
          </li>
        )}
      </ul>
    );
  }
}

export default Wishlist;
