import React from "react";

const Add = () => (
  <svg height="100%" width="100%" viewBox="0 0 100 100">
    <line
      x1="50"
      y1="10"
      x2="50"
      y2="90"
      style={{ stroke: "darkgray", strokeWidth: 8 }}
    />
    <line
      x1="10"
      y1="50"
      x2="90"
      y2="50"
      style={{ stroke: "darkgray", strokeWidth: 8 }}
    />
  </svg>
);

export default Add;
