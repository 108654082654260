import { FIREBASE_INIT } from "../actions/actions";

const initialState = {};

function firebaseReducer(state = initialState, action) {
  switch (action.type) {
    case FIREBASE_INIT:
      return action.payload.firebase;
    //DEFAULT
    default:
      return state;
  }
}

export default firebaseReducer;
