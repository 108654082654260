import React from "react";
import { authenticate } from "../../modules/actions/actions";
import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import { connect } from "react-redux";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      this.props.authenticate();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.props.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  const mapStateToProps = state => ({
    authUser: state.user
  });

  const mapDispatchToProps = {
    authenticate
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withFirebase(WithAuthentication));
};

export default withAuthentication;
