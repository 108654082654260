import React, { Component } from "react";
import { connect } from "react-redux";
import { withAuthorization } from "../Session";
import {
  getShoppingList,
  setBuyer,
  setBought,
  removeBuying,
  removeBought,
  saveGift,
  removeGift
} from "../../modules/actions/actions";
import CheckMark from "../SVGs/checkMark";
import Circle from "../SVGs/emptyCircle";
import GiftDisplay from "../GiftDisplay";
import EditGiftModal from "../EditGiftModal";

class ShoppingCartPage extends Component {
  constructor(props) {
    super(props);
    this.state = { activeItem: null, newItem: false };
  }

  componentDidMount() {
    this.props.getShoppingList();
  }
  handleItemClick = (item, key) => {
    this.setState({
      activeItem: item,
      activeItemId: key
    });
  };
  handleSetBuyer = (item, itemId) => {
    this.props.setBuyer(item, itemId, this.props.user.uid);
  };
  handleSetBought = (item, itemId) => {
    this.props.setBought(item, itemId, "BOUGHT");
  };
  getUserForId = uid => {
    var found = this.props.users.find(function(user) {
      return user.uid === uid;
    });
    return found;
  };
  openModal = () => {
    this.setState({ showModal: true });
  };
  deleteGift = giftId => {
    this.props.removeGift(giftId, this.state.activeItem);
    const cartKeys = Object.keys(this.props.cart);
    var found = cartKeys.find(function(key) {
      return key !== giftId;
    });
    if (found) {
      this.handleItemClick(this.props.cart[found], found);
    } else {
      this.setState({ activeItemId: "", activeItem: undefined });
    }
  };
  closeModal = () => {
    this.setState({ showModal: false, newItem: false });
  };
  saveModal = (item, newLinks, owner) => {
    //saveGift(itemId, item, newLinks, owner, newItem, buying)
    const itemId = this.state.newItem ? undefined : this.state.activeItemId;
    this.props.saveGift(
      itemId,
      item,
      newLinks,
      owner,
      this.state.newItem,
      true
    );
    this.setState({ showModal: false, newItem: false });
  };
  addItemClick = () => {
    this.setState({
      showModal: true,
      newItem: true,
      activeItem: undefined,
      activeItemId: undefined
    });
  };
  render() {
    return (
      <div>
        <ol className="breadcrumb custom-breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            Shopping Cart
          </li>
        </ol>
        <div className="container">
          <div className="row">
            <CartList
              cart={this.props.cart}
              getUserForId={this.getUserForId}
              handleItemClick={this.handleItemClick}
              activeItemId={this.state.activeItemId}
              addItemClick={this.addItemClick}
            />
            {this.state.activeItem ? (
              <div className="col-md-6 card border-info">
                <GiftDisplay
                  item={this.props.cart[this.state.activeItemId]}
                  itemId={this.state.activeItemId}
                  userId={this.props.user.uid}
                  handleSetBought={this.handleSetBought}
                  handleSetBuyer={this.handleSetBuyer}
                  showTitle={true}
                  handleRemoveBuyer={this.props.removeBuying}
                  handleRemoveBought={this.props.removeBought}
                  handleDelete={this.deleteGift}
                  onEdit={this.openModal}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {this.state.showModal && (
          <EditGiftModal
            show={this.state.showModal}
            closeModal={this.closeModal}
            saveModal={this.saveModal}
            item={this.state.activeItem}
            newItem={this.state.newItem}
            connections={this.props.connections}
            users={this.props.users}
          />
        )}
      </div>
    );
  }
}

class CartList extends Component {
  render() {
    const { cart } = this.props;
    const keys = cart ? Object.keys(cart) : [];

    return (
      <ul className="list-group flex-column col-md-6 clickable-group">
        {keys.map(key => {
          const item = cart[key];
          const user = this.props.getUserForId(item.owner);
          const username = user ? user.username : "N/A";
          const isActive = this.props.activeItemId === key;
          const className = isActive
            ? "list-group-item shopping-cart-item list-group-item-primary"
            : "list-group-item shopping-cart-item list-group-item-action";
          return (
            <li
              className={className}
              key={key}
              onClick={() => this.props.handleItemClick(item, key)}
            >
              <div style={{ display: "flex" }}>
                <div className="wishlist-icon">
                  {item.boughtStatus === "BOUGHT" ? <CheckMark /> : <Circle />}
                </div>
                <span>{item.title}</span>
              </div>
              <div> for {username}</div>
            </li>
          );
        })}
        <li
          className="list-group-item  list-group-item-action"
          key="addOne"
          onClick={this.props.addItemClick}
        >
          Add an item
        </li>
      </ul>
    );
  }
}

const mapStateToProps = state => ({
  cart: state.shoppingCart,
  user: state.user,
  users: state.users,
  connections: state.user.connections
});
const mapDispatchToProps = {
  getShoppingList,
  setBuyer,
  setBought,
  removeBuying,
  removeBought,
  saveGift,
  removeGift
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(
  connect(mapStateToProps, mapDispatchToProps)(ShoppingCartPage)
);
