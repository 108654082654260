import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withAuthorization } from "../Session";
import { sendQuestion } from "../../modules/actions/actions";

class NotificationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { questionTo: "none", question: "" };
  }

  getUserForId = (uid) => {
    var found = this.props.users.find(function (user) {
      return user.uid === uid;
    });
    if (!found) {
      return {};
    }
    return found;
  };

  onQuestionChange = (e) => {
    this.setState({ question: e.target.value });
  };

  handleQuestionToChange = (e) => {
    this.setState({
      questionTo: e.target.value,
    });
  };

  onQuestionSend = () => {
    this.props.sendQuestion(this.state.questionTo, this.state.question);
    this.setState({ questionTo: "none", question: "" });
  };

  validateSave = () => {
    return this.state.questionTo && this.state.questionTo !== "none";
  };

  render() {
    console.log("re-render");
    const { question, questionTo } = this.state;
    const connectionKeys = this.props.connections
      ? Object.keys(this.props.connections)
      : [];
    const questionKeys = this.props.questions
      ? Object.keys(this.props.questions)
      : [];
    return (
      <div>
        <ol className="breadcrumb custom-breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            Notifications
          </li>
        </ol>
        <div className="container">
          <h3>Questions for you</h3>
          <ul>
            {questionKeys.map((questionKey) => {
              var question = this.props.questions[questionKey];
              if (question.questionTo === this.props.user.uid) {
                return <li key={questionKey}>{question.questionText}</li>;
              }
              return "";
            })}
          </ul>
          <h3>Questions from you</h3>
          <ul>
            {questionKeys.map((questionKey) => {
              var question = this.props.questions[questionKey];
              if (question.questionFrom === this.props.user.uid) {
                return <li key={questionKey}>{question.questionText}</li>;
              }
              return "";
            })}
          </ul>
          <h3>Add a question</h3>
          <form>
            <div className="form-group row">
              <label htmlFor="title">It's for:</label>
              <div>
                {connectionKeys && connectionKeys.length > 1 ? (
                  <select
                    className="custom-select"
                    value={this.state.questionTo}
                    onChange={this.handleQuestionToChange}
                  >
                    <option value="none" key="none">
                      Select
                    </option>
                    {connectionKeys.map((connection) => (
                      <option value={connection} key={connection}>
                        {this.getUserForId(connection).username}
                      </option>
                    ))}
                  </select>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="question">Question</label>
              <div>
                <textarea
                  type="text"
                  className="form-control"
                  id="question"
                  value={question || ""}
                  onChange={this.onQuestionChange}
                  required
                />
              </div>
            </div>
            <Button
              variant="primary"
              onClick={this.onQuestionSend}
              disabled={!this.validateSave()}
            >
              Save Changes
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  users: state.users,
  connections: state.user.connections,
  questions: state.questions,
});
const mapDispatchToProps = {
  sendQuestion,
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsPage)
);
