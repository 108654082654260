import React from "react";
import { connect } from "react-redux";

class Spinner extends React.Component {
  componentDidMount() {
    //this.props.dispatch(fetchProducts());
  }

  render() {
    if (this.props.loading) {
      return (
        <div className="d-flex justify-content-center app-spinner">
          <div className="spinner-border text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    return <React.Fragment></React.Fragment>;
  }
}

const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Spinner);
