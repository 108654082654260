import React, { Component } from "react";
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import { withAuthorization } from "../Session";
import Wishlist from "./wishlist";
//import ItemModal from "./addItemModal";
import EditGiftModal from "../EditGiftModal";
import GiftDisplay from "../GiftDisplay";
import { connect } from "react-redux";
import {
  saveGift,
  changeActiveGift,
  removeGift
} from "../../modules/actions/actions";

const INITIAL_STATE = {
  showModal: false,
  newItem: false
};

const NEW_ITEM = {
  title: "New Item",
  description: "Please enter a description"
};

class WishlistPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleItemClick = key => {
    this.setState({
      newItem: false,
      activeItemId: key
    });
    this.props.changeActiveGift(key);
  };

  handleAddItemClick = () => {
    this.setState({
      showModal: true,
      newItem: true,
      activeItemId: ""
    });
    this.props.changeActiveGift("");
  };

  handleRemoveGift = itemId => {
    this.props.removeGift(itemId, this.props.wishlist[itemId]);
    const wishlistKeys = Object.keys(this.props.wishlist);
    var found = wishlistKeys.find(function(key) {
      return key !== itemId;
    });
    if (found) {
      this.handleItemClick(found);
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  saveModal = (item, newLinks) => {
    this.props.saveGift(
      this.props.wishlistKey,
      item,
      newLinks,
      null,
      this.state.newItem
    );

    this.setState({
      showModal: false,
      newItem: false
    });
  };

  editItem = () => {
    this.setState({
      showModal: true
    });
  };

  handleToggleListType = val => {
    this.setState({ listType: val });
    var newKeys = Object.keys(
      val === 2 ? this.props.pastWishlist : this.props.wishlist
    );
    this.handleItemClick(newKeys[0]);
  };

  render = () => {
    const modalItem = this.state.newItem
      ? NEW_ITEM
      : this.props.wishlist
      ? this.props.wishlist[this.props.wishlistKey]
      : {};
    return (
      <div>
        <ol className="breadcrumb custom-breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            Wishlist
          </li>
        </ol>

        <div className="container">
          <div className="row">
            <div className="flex-column col-md-6">
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  defaultValue={1}
                  value={this.state.listType}
                  onChange={this.handleToggleListType}
                >
                  <ToggleButton value={1}>Current</ToggleButton>
                  <ToggleButton value={2}>Past</ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
              <Wishlist
                itemClick={this.handleItemClick}
                addItem={this.handleAddItemClick}
                list={
                  this.state.listType === 2
                    ? this.props.pastWishlist
                    : this.props.wishlist
                }
                activeKey={this.props.wishlistKey}
                loading={this.props.loading}
                showAddItem={this.state.listType !== 2}
              />
            </div>
            {this.props.wishlist && (
              <div className="col-md-6 card border-info">
                <GiftDisplay
                  item={
                    this.state.listType === 2
                      ? this.props.pastWishlist[this.props.wishlistKey]
                      : this.props.wishlist[this.props.wishlistKey]
                  }
                  itemId={this.props.wishlistKey}
                  userId={this.props.userId}
                  onEdit={this.editItem}
                  showTitle={true}
                  isOwner={true}
                  handleDelete={this.handleRemoveGift}
                  isPastItem={this.state.listType === 2}
                />
              </div>
            )}
          </div>
        </div>

        {this.state.showModal && (
          <EditGiftModal
            show={this.state.showModal}
            closeModal={this.closeModal}
            saveModal={this.saveModal}
            item={modalItem}
            newItem={this.state.newItem}
          />
        )}
      </div>
    );
  };
}

const condition = authUser => !!authUser;

const mapStateToProps = state => ({
  wishlist: state.user.wishlist,
  pastWishlist: state.user.pastWishlist,
  wishlistKey: state.user.wishlistKey,
  loading: state.loading,
  userId: state.user.uid
});

const mapDispatchToProps = {
  saveGift,
  changeActiveGift,
  removeGift
};

export default withAuthorization(condition)(
  connect(mapStateToProps, mapDispatchToProps)(WishlistPage)
);
