import React, { Component } from "react";
import { connect } from "react-redux";
import { withAuthorization } from "../Session";
import ConnectionModal from "./addConnectionModal";
import RemoveConnectionModal from "./removeConnectionModal";
import EditGiftModal from "../EditGiftModal";
import ConnectionWishlistDisplay from "./connectionWishlist";
import "./connectionPage.scss";

import {
  saveConnection,
  getWishlistForConnection,
  setBuyer,
  setBought,
  removeBought,
  removeBuying,
  saveGift,
  removeGift
} from "../../modules/actions/actions";

class ConnectionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showEditItemModal: false,
      showReviewRemoveConnection: false
    };
  }

  handleUserClick = user => {
    this.props.getWishlistForConnection(user);
  };
  handleAddClick = () => {
    this.setState({
      showModal: true
    });
  };
  handleAddConnection = uid => {
    this.props.saveConnection(uid);
    this.setState({
      showModal: false
    });
  };
  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };
  //addItem, removeConnection, onEdit
  handleAddItem = () => {
    this.setState({
      showEditItemModal: true,
      newItem: true,
      activeItem: {},
      activeItemId: null
    });
  };
  handleEditItem = (item, itemId) => {
    this.setState({
      showEditItemModal: true,
      activeItem: item,
      activeItemId: itemId,
      newItem: false
    });
  };
  handleItemEditClose = () => {
    this.setState({
      showEditItemModal: false,
      activeItem: null,
      activeItemId: null,
      newItem: false
    });
  };
  handleDeleteGift = (giftId, gift) => {
    this.props.removeGift(giftId, gift);
  };
  saveItemModal = (item, newLinks, owner) => {
    //saveGift(itemId, item, newLinks, owner, newItem, buying)
    const itemId = this.state.newItem ? undefined : this.state.activeItemId;
    this.props.saveGift(itemId, item, newLinks, owner, this.state.newItem);
    this.setState({ showEditItemModal: false, newItem: false });
  };
  //REMOVE CONNECTION FUNCTIONS
  handleRemoveConnection = () => {
    this.setState({ showReviewRemoveConnection: true });
  };
  handleRemoveReviewClose = () => {
    this.setState({ showReviewRemoveConnection: false });
  };

  render() {
    const wishlist = this.props.currentConnection
      ? this.props.currentConnection.wishlist
      : {};
    return (
      <div>
        <ol className="breadcrumb custom-breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            Connections:
            {this.props.currentConnection
              ? " " + this.props.currentConnection.username
              : ""}
          </li>
        </ol>

        <div className="container connections-container">
          <div className="row">
            <ConnectionsList
              handleAddClick={this.handleAddClick}
              connections={this.props.connections}
              users={this.props.users}
              handleUserClick={this.handleUserClick}
              currentConnection={this.props.currentConnection}
            />
            <div className="col-md-8 connection-wishlist">
              <ConnectionWishlistDisplay
                list={wishlist}
                users={this.props.users}
                user={this.props.user}
                handleSetBuyer={this.props.setBuyer}
                handleSetBought={this.props.setBought}
                removeBuying={this.props.removeBuying}
                removeBought={this.props.removeBought}
                connection={this.props.currentConnection}
                addItem={this.handleAddItem}
                removeConnection={this.handleRemoveConnection}
                onEdit={this.handleEditItem}
                handleDelete={this.handleDeleteGift}
              />
            </div>
          </div>
        </div>
        {this.state.showModal && (
          <ConnectionModal
            show={this.state.showModal}
            users={this.props.users}
            connections={this.props.connections}
            saveModal={this.handleAddConnection}
            closeModal={this.handleCloseModal}
            user={this.props.user}
          />
        )}
        {this.state.showReviewRemoveConnection && (
          <RemoveConnectionModal
            show={this.state.showReviewRemoveConnection}
            closeModal={this.handleRemoveReviewClose}
          />
        )}
        {this.state.showEditItemModal && (
          <EditGiftModal
            show={this.state.showEditItemModal}
            closeModal={this.handleItemEditClose}
            saveModal={this.saveItemModal}
            item={this.state.activeItem}
            newItem={this.state.newItem}
            owner={this.props.currentConnection.userId}
            users={this.props.users}
          />
        )}
      </div>
    );
  }
}

class ConnectionsList extends Component {
  findUserWithId = id => {
    return;
  };
  render() {
    const connectionsList = [];
    for (var i = 0; i < this.props.users.length; i++) {
      var user = this.props.users[i];
      if (this.props.connections[user.uid]) {
        connectionsList.push(user);
      }
    }

    return (
      <div className="col-md-4 list-group list-group-flush clickable-group connection-list">
        <div
          className="list-group-item list-group-item-action add-one-button"
          key="addOne"
          onClick={this.props.handleAddClick}
        >
          <span className="add-new-mobile">+ Add</span>
          <span className="add-new-desktop">Add a new connection</span>
        </div>
        {connectionsList.map((user, index) => {
          const isActive =
            this.props.currentConnection &&
            this.props.currentConnection.userId === user.uid;
          const className = isActive
            ? "list-group-item list-group-item-primary"
            : "list-group-item list-group-item-action";
          return (
            <div
              className={className}
              key={index}
              onClick={() => this.props.handleUserClick(user)}
            >
              {user.username}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentConnection: state.currentConnection,
  connections: state.user.connections,
  users: state.users,
  user: state.user,
  loading: state.loading
});
const mapDispatchToProps = {
  saveConnection,
  getWishlistForConnection,
  setBuyer,
  setBought,
  removeBuying,
  removeBought,
  saveGift,
  removeGift
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(
  connect(mapStateToProps, mapDispatchToProps)(ConnectionsPage)
);
