import React, { Component } from "react";
import { connect } from "react-redux";
import { withFirebase } from "../Firebase";
import { fetchUsers } from "../../modules/actions/actions";

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: []
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  render() {
    return (
      <div>
        <h1>Admin</h1>

        {this.props.loading && <div>Loading ...</div>}

        {this.props.users && <UserList users={this.props.users} />}
      </div>
    );
  }
}

const UserList = ({ users }) => (
  <ul>
    {users.map(user => (
      <li key={user.uid}>
        <span>
          <strong>ID:</strong> {user.uid}
        </span>
        <span>
          <strong>E-Mail:</strong> {user.email}
        </span>
        <span>
          <strong>Username:</strong> {user.username}
        </span>
      </li>
    ))}
  </ul>
);

const mapStateToProps = state => ({
  loading: state.loading,
  firebase: state.firebase,
  users: state.users
});

const mapDispatchToProps = {
  fetchUsers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(AdminPage));
