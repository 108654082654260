import React from "react";

const ShoppingBag = () => (
  <svg height="100%" width="100%" viewBox="0 0 100 100">
    <polygon
      points="20,35 70,35 90,85 0,85"
      style={{ fill: "green", stroke: "green", strokeWidth: 1 }}
    />
    <path
      d="M 25 37 q 20 -60 40 0"
      stroke="green"
      strokeWidth="5"
      fill="none"
    />
  </svg>
);

export default ShoppingBag;
