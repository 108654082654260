import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import ShoppingBag from "../SVGs/shoppingBag";
import CheckMark from "../SVGs/checkMark";
import CircleCross from "../SVGs/circleCross";
import GiftDisplay from "../GiftDisplay";
import "./connectionWishlist.scss";

const BOUGHT = "BOUGHT";

class ConnectionWishlistDisplay extends Component {
  handleSetBuyer = (item, itemId) => {
    this.props.handleSetBuyer(item, itemId, this.props.user.uid, true);
  };
  handleSetBought = (item, itemId) => {
    this.props.handleSetBought(item, itemId, BOUGHT, true);
  };
  handleAdd = () => {
    this.props.addItem && this.props.addItem();
  };
  handleRemove = () => {
    this.props.removeConnection && this.props.removeConnection();
  };
  handleEdit = (item, itemId) => {
    this.props.onEdit && this.props.onEdit(item, itemId);
  };
  getIsMyItem = item => {
    return this.props.user.uid && item.buyer === this.props.user.uid;
  };
  renderSVG = item => {
    if (this.getIsMyItem(item)) {
      if (item.boughtStatus === BOUGHT) {
        return <CheckMark />;
      } else {
        return <ShoppingBag />;
      }
    } else if (item.buyer) {
      return <CircleCross />;
    }
    return "";
  };

  render() {
    var keys = this.props.list ? Object.keys(this.props.list) : [];
    return (
      <>
        {this.props.connection ? (
          <div className="connection-list-header">
            <div className="user-box">
              <div className="connection-name">
                {this.props.connection.username}
              </div>
              <div
                className="remove-connection-link"
                onClick={this.handleRemove}
              >
                (remove connection)
              </div>
            </div>
            <div className="button-box">
              <Button variant="light" onClick={this.handleAdd}>
                Add item
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
        {keys.length ? (
          <Accordion defaultActiveKey="0">
            {keys.map((key, index) => {
              return (
                <Card key={index}>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={index}
                      className="wishlist-accordion"
                    >
                      <div>{this.props.list[key].title}</div>
                      <div className="wishlist-icon">
                        {this.renderSVG(this.props.list[key])}
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={index}>
                    <Card.Body>
                      <GiftDisplay
                        item={this.props.list[key]}
                        itemId={key}
                        userId={this.props.user.uid}
                        handleSetBought={this.handleSetBought}
                        handleSetBuyer={this.handleSetBuyer}
                        handleRemoveBuyer={this.props.removeBuying}
                        handleRemoveBought={this.props.removeBought}
                        onEdit={this.handleEdit}
                        handleDelete={this.props.handleDelete}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        ) : (
          <span>
            {this.props.connection
              ? "No list found for user"
              : "No user selected"}
          </span>
        )}
      </>
    );
  }
}
export default ConnectionWishlistDisplay;
