import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";

import App from "./components/App";
import Firebase, { FirebaseContext } from "./components/Firebase";

import { Provider } from "react-redux";
import Store from "./modules/store";
import { firebaseInit } from "./modules/actions/actions";

var firebase = new Firebase();
const store = Store();
store.dispatch(firebaseInit(firebase));

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={firebase}>
      <App />
    </FirebaseContext.Provider>
  </Provider>,

  document.getElementById("root")
);

serviceWorker.unregister();
