export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const WISHLIST = "/wishlist";
export const SHOPPING_CART = "/shopping-cart";
export const PEOPLE = "/people";
export const NOTIFICATIONS = "/notifications";
