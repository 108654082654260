import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class ErrorModal extends Component {
  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.error}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Dismiss
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default ErrorModal;
