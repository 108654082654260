import {
  FETCH_USERS_BEGIN,
  LOGOUT,
  SET_ERROR,
  DISMISS_ERROR
} from "../actions/actions";

const initialState = null;

function errorReducer(state = initialState, action) {
  switch (action.type) {
    //ERROR
    case SET_ERROR:
      return action.payload.error;
    case DISMISS_ERROR:
    case FETCH_USERS_BEGIN:
    case LOGOUT:
      return null;
    //DEFAULT
    default:
      return state;
  }
}

export default errorReducer;
