import "firebase/auth";
import "firebase/database";

export const FETCH_USERS_BEGIN = "FETCH_USERS_BEGIN";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const START_AUTHENTICATION = "START_AUTHENTICATION";
export const FINISH_AUTHENTICATION = "FINISH_AUTHENTICATION";
export const FETCH_WISHLIST_SUCCESS = "FETCH_WISHLIST_SUCCESS";
export const FETCH_CONNECTIONS = "FETCH_CONNECTIONS";
export const FIREBASE_INIT = "FIREBASE_INIT";
export const LOGOUT = "LOGOUT";
//wishlist page
export const SAVE_GIFT_BEGIN = "SAVE_GIFT_BEGIN";
export const SAVE_GIFT_SUCCESS = "SAVE_GIFT_SUCCESS";
export const CREATE_GIFT_BEGIN = "CREATE_GIFT_BEGIN";
export const CREATE_GIFT_SUCCESS = "CREATE_GIFT_SUCCESS";
export const CLICK_WISHLIST_ITEM = "CLICK_WISHLIST_ITEM";
export const CREATE_NEW_LINK = "CREATE_NEW_LINK";
//connection page
export const GET_CONNECTION_GIFTS_BEGIN = "START_GET_WISHLIST_FOR_CONNECTION";
export const GET_CONNECTION_GIFTS_SUCCESS =
  "FINISH_GET_WISHLIST_FOR_CONNECTION";
export const SAVE_CONNECTION_START = "SAVE_CONNECTION_START";
export const SAVE_CONNECTION_SUCCESS = "SAVE_CONNECTION_SUCCESS";
export const SET_BUYER_START = "SET_BUYER_START";
export const SET_BOUGHT_OR_BUYER_SUCCESS = "SET_BOUGHT_BUYER_SUCCESS";
export const SET_BOUGHT_START = "SET_BOUGHT_START";
//shopping cart
export const GET_CART_START = "GET_CART_START";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
//remove items
export const REMOVE_BOUGHT_START = "REMOVE_BOUGHT_START";
export const REMOVE_BOUGHT_SUCCESS = "REMOVE_BOUGHT_SUCCESS";
export const REMOVE_BUYING_START = "REMOVE_BUYING_START";
export const REMOVE_BUYING_SUCCESS = "REMOVE_BUYING_SUCCESS";
export const REMOVE_GIFT_START = "REMOVE_GIFT_START";
export const REMOVE_GIFT_SUCCESS = "REMOVE_GIFT_SUCCESS";
export const REMOVE_LINK_START = "REMOVE_LINK_START";
export const REMOVE_LINK_SUCCESS = "REMOVE_LINK_SUCCESS";
export const REMOVE_CONNECTION_START = "REMOVE_CONNECTION_START";
export const REMOVE_CONNECTION_SUCCESS = "REMOVE_CONNECTION_SUCCESS";
//set given
export const SET_GIVEN_START = "SET_GIVEN_START";
export const SET_GIVEN_SUCCESS = "SET_GIVEN_SUCCESS";
//error
export const SET_ERROR = "SET_ERROR";
export const DISMISS_ERROR = "DISMISS_ERROR";
//questions
export const QUESTION_SEND_START = "QUESTION_SEND_START";
export const QUESTION_SEND_SUCCESS = "QUESTION_SEND_SUCCESS";
export const QUESTION_ANSWER_START = "QUESTION_ANSWER_START";
export const QUESTION_ANSWER_SUCCESS = "QUESTION_ANSWER_SUCCESS";
export const GET_QUESTIONS_START = "GET_QUESTIONS_START";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";

/* FETCH ADMIN FROM FIREBASE */
export const fetchUsersBegin = (reference) => ({
  type: FETCH_USERS_BEGIN,
  payload: { reference },
});
export const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  payload: { users },
});
export function fetchUsers() {
  return (dispatch, getState) => {
    const dbUsers = getState().firebase.db.ref("users");
    dispatch(fetchUsersBegin(dbUsers));
    dbUsers.on("value", (snapshot) => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map((key) => ({
        ...usersObject[key],
        uid: key,
      }));

      dispatch(fetchUsersSuccess(usersList));
      dbUsers.off();
    });
  };
}

/* FETCH USER OBJECT FROM FIREBASE */
export const authenticateBegin = () => ({
  type: START_AUTHENTICATION,
});
export const authenticateSuccess = (user) => ({
  type: FINISH_AUTHENTICATION,
  payload: { user },
});
export const fetchWishlistSuccess = (wishlist) => ({
  type: FETCH_WISHLIST_SUCCESS,
  payload: { wishlist },
});
export const fetchConnections = (connections) => ({
  type: FETCH_CONNECTIONS,
  payload: { connections },
});
export function authenticate() {
  return (dispatch, getState) => {
    dispatch(authenticateBegin());
    getState().firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        //check that user has been created - this is mostly because of testing prior to creating users
        getState()
          .firebase.db.ref("users/" + authUser.uid)
          .once("value", (snapshot) => {
            if (!snapshot.exists()) {
              getState().firebase.user(authUser.uid).set({
                email: authUser.email,
              });
            }
          });
        dispatch(authenticateSuccess(authUser));
        //get wishlist for user
        if (authUser.uid) {
          const giftsRef = getState().firebase.db.ref("gifts");
          giftsRef
            .orderByChild("owner")
            .equalTo(getState().user.uid)
            .once("value")
            .then(function (gifts) {
              //todo: filter out private gifts
              dispatch(fetchWishlistSuccess(gifts.val()));
            });
        }
        //get connections for user
        getState()
          .firebase.db.ref("users/" + getState().user.uid + "/connections")
          .once("value", (snapshot) => {
            dispatch(fetchConnections(snapshot.val()));
          });
        //get other users
        dispatch(fetchUsers());
        dispatch(getQuestions());
      }
    });
  };
}

/* INITIALIZE FIREBASE APP */
export const firebaseInit = (firebase) => ({
  type: "FIREBASE_INIT",
  payload: { firebase: firebase },
});
/* LOGOUT OF FIREBASE APP */
export const logoutComplete = () => ({
  type: LOGOUT,
});
export function firebaseLogout() {
  return (dispatch, getState) => {
    getState().firebase.auth.signOut();
    dispatch(logoutComplete());
  };
}

/* GET WISHLIST FOR CONNECTION */
export const startGetWishlistForConnection = () => ({
  type: GET_CONNECTION_GIFTS_BEGIN,
});
export const finishGetWishlistForConnection = (wishlist, user, userId) => ({
  type: GET_CONNECTION_GIFTS_SUCCESS,
  payload: { wishlist, user, userId },
});
export function getWishlistForConnection(user) {
  return (dispatch, getState) => {
    dispatch(startGetWishlistForConnection());
    const giftsRef = getState().firebase.db.ref("gifts");
    giftsRef
      .orderByChild("owner")
      .equalTo(user.uid)
      .once("value")
      .then(function (gifts) {
        dispatch(
          finishGetWishlistForConnection(gifts.val(), user, getState().user.uid)
        );
      });
  };
}
/* SAVE CONNECTION FOR USER */
export const saveConnectionStart = () => ({
  type: SAVE_CONNECTION_START,
});
export const saveConnectionSuccess = (connection) => ({
  type: SAVE_CONNECTION_SUCCESS,
  payload: { connection },
});
export function saveConnection(connection) {
  return (dispatch, getState) => {
    dispatch(saveConnectionStart());
    const payload = {
      connectionId: connection,
    };
    const connections = getState().firebase.db.ref(
      "users/" + getState().user.uid + "/connections"
    );
    connections.push(payload, function (error) {
      if (error) {
        dispatch(setError(error));
      } else {
        dispatch(saveConnectionSuccess(connection));
      }
    });
  };
}
/* SET BUYER FOR GIFT */
export const setBuyerStart = () => ({
  type: SET_BUYER_START,
});
export const setBoughtOrBuyerSuccess = (
  item,
  itemId,
  setConnectionWishlist
) => ({
  type: SET_BOUGHT_OR_BUYER_SUCCESS,
  payload: { item, itemId, setConnectionWishlist },
});
export function setBuyer(item, itemId, userId, setConnectionWishlist) {
  return (dispatch, getState) => {
    dispatch(setBuyerStart());
    const payload = {
      ...item,
      buyer: userId,
    };
    getState()
      .firebase.db.ref("gifts/" + itemId)
      .set(payload, function (error) {
        if (error) {
          dispatch(setError(error));
        } else {
          dispatch(
            setBoughtOrBuyerSuccess(payload, itemId, setConnectionWishlist)
          );
        }
      });
  };
}
/* SET BOUGHT STATUS FOR GIFT */
export const setBoughtStart = () => ({
  type: SET_BOUGHT_START,
});
export function setBought(item, itemId, status, setConnectionWishlist) {
  return (dispatch, getState) => {
    dispatch(saveConnectionStart());
    const payload = {
      ...item,
      boughtStatus: status,
    };
    getState()
      .firebase.db.ref("gifts/" + itemId)
      .set(payload, function (error) {
        if (error) {
          dispatch(setError(error));
        } else {
          dispatch(
            setBoughtOrBuyerSuccess(payload, itemId, setConnectionWishlist)
          );
        }
      });
  };
}
export const setError = (error) => ({
  type: SET_ERROR,
  payload: { error },
});
export const dismissError = () => ({
  type: DISMISS_ERROR,
});

/* SET GIVEN STATUS FOR GIFT */
export const setGivenStart = () => ({
  type: SET_GIVEN_START,
});
export const setGivenSuccess = (gift, giftId, userIsOwner) => ({
  type: SET_GIVEN_SUCCESS,
  payload: {
    gift,
    giftId,
    userIsOwner,
  },
});
export function setGiven(item, itemId) {
  return (dispatch, getState) => {
    dispatch(setGivenStart());
    const payload = {
      ...item,
      givenStatus: "GIVEN",
    };
    getState()
      .firebase.db.ref("gifts/" + itemId)
      .set(payload, function (error) {
        if (error) {
          dispatch(setError(error));
        } else {
          const userIsOwner = item.owner === getState().user.uid;
          dispatch(setGivenSuccess(payload, itemId, userIsOwner));
        }
      });
  };
}

/* CHANGE ACTIVE GIFT */
export const changeActiveGift = (key) => ({
  type: CLICK_WISHLIST_ITEM,
  payload: { key },
});
/* SAVE GIFT */
export const saveGiftBegin = () => ({
  type: SAVE_GIFT_BEGIN,
});
export const saveGiftSuccess = (gift, giftOwner, giftId, userIsOwner) => ({
  type: SAVE_GIFT_SUCCESS,
  payload: { gift, giftOwner, giftId, userIsOwner },
});
export const createGiftBegin = () => ({
  type: CREATE_GIFT_BEGIN,
});
export const createGiftSuccess = (gift, giftOwner, buying, userIsOwner) => ({
  type: CREATE_GIFT_SUCCESS,
  payload: { gift, giftOwner, buying, userIsOwner },
});
export const createNewLink = (
  link,
  linkId,
  giftId,
  giftOwner,
  buying,
  userIsOwner
) => ({
  type: CREATE_NEW_LINK,
  payload: {
    link,
    linkId,
    giftId,
    giftOwner,
    buying,
    userIsOwner,
  },
});
export function createNewLinksForGift(links, giftRef, giftOwner, buying) {
  return (dispatch, getState) => {
    const linksRef = getState().firebase.db.ref("gifts/" + giftRef + "/links");
    const linkKeys = links ? Object.keys(links) : [];
    linkKeys.forEach(function (linkKey) {
      var newLinkRef = linksRef.push(links[linkKey], function (error) {
        if (error) {
          dispatch(setError(error));
        } else {
          var id = newLinkRef.key;
          var userIsOwner = getState().user.uid === giftOwner;
          dispatch(
            createNewLink(
              links[linkKey],
              id,
              giftRef,
              giftOwner,
              buying,
              userIsOwner
            )
          );
        }
      });
    });
  };
}
/**
 * Save Gift
 * @param {*} itemId - id of item
 * @param {*} item - required
 * @param {*} newLinks - object for new links
 * @param {*} owner - uid: if owner is undefined, will assume current user.  shopping cart page and connections page must pass owner
 * @param {*} newItem - Boolean to say if this is a new item or not
 * @param {*} buying - Boolean to auto-set buying on a gift.  Shopping cart should pass true.
 */
export function saveGift(itemId, item, newLinks, owner, newItem, buying) {
  return (dispatch, getState) => {
    var payload;
    const uid = getState().user.uid;
    const giftOwner = owner ? owner : uid;
    const creator = giftOwner === uid ? null : uid;
    const userIsOwner = uid === giftOwner;
    if (newItem) {
      const gifts = getState().firebase.db.ref("gifts");
      payload = {
        ...item,
        links: {},
        owner: giftOwner,
        buyer: null,
      };
      if (creator) {
        payload.creator = creator;
      }
      if (buying) {
        payload.buyer = uid;
      }
      dispatch(createGiftBegin());
      var newGiftRef = gifts.push(payload, function (error) {
        if (error) {
          dispatch(setError(error));
        } else {
          payload.id = newGiftRef.key;
          dispatch(createGiftSuccess(payload, giftOwner, buying, userIsOwner));
          dispatch(
            createNewLinksForGift(
              newLinks,
              newGiftRef.key,
              giftOwner,
              buying,
              userIsOwner
            )
          );
        }
      });
    } else {
      dispatch(saveGiftBegin());
      getState()
        .firebase.db.ref("gifts/" + itemId)
        .set(item, function (error) {
          if (error) {
            dispatch(setError(error));
          } else {
            dispatch(saveGiftSuccess(item, giftOwner, itemId, userIsOwner));
            if (newLinks) {
              dispatch(
                createNewLinksForGift(
                  newLinks,
                  itemId,
                  giftOwner,
                  buying,
                  userIsOwner
                )
              );
            }
          }
        });
    }
  };
}

/* SHOPPING LIST */
export const getCartStart = () => ({
  type: GET_CART_START,
});
export const getCartSuccess = (cart) => ({
  type: GET_CART_SUCCESS,
  payload: { cart },
});
export function getShoppingList() {
  return (dispatch, getState) => {
    //get to-buy list for user
    dispatch(getCartStart());
    const giftsRef = getState().firebase.db.ref("gifts");
    giftsRef
      .orderByChild("buyer")
      .equalTo(getState().user.uid)
      .once("value")
      .then(function (gifts) {
        dispatch(getCartSuccess(gifts.val()));
      });
  };
}

//REMOVE ITEMS
export const removeBoughtStart = () => ({
  type: REMOVE_BOUGHT_START,
});
export const removeBoughtFinish = (giftId) => ({
  type: REMOVE_BOUGHT_SUCCESS,
  payload: { giftId },
});
export function removeBought(giftId) {
  return (dispatch, getState) => {
    //get to-buy list for user
    dispatch(removeBoughtStart());
    const giftsRef = getState().firebase.db.ref("gifts/" + giftId);
    giftsRef.child("boughtStatus").remove();
    dispatch(removeBoughtFinish(giftId));
  };
}
export const removeBuyingStart = () => ({
  type: REMOVE_BUYING_START,
});
export const removeBuyingSuccess = (giftId) => ({
  type: REMOVE_BUYING_SUCCESS,
  payload: { giftId },
});
export function removeBuying(giftId) {
  return (dispatch, getState) => {
    dispatch(removeBuyingStart());
    const giftsRef = getState().firebase.db.ref("gifts/" + giftId);
    giftsRef.child("buyer").remove();
    dispatch(removeBuyingSuccess(giftId));
  };
}
export const removeLinkStart = () => ({
  type: REMOVE_LINK_START,
});
export const removeLinkSuccess = (giftId, linkId) => ({
  type: REMOVE_LINK_SUCCESS,
  payload: { giftId, linkId },
});
export function removeLink(giftId, linkId) {
  return (dispatch, getState) => {
    dispatch(removeLinkStart());
    const giftsRef = getState().firebase.db.ref("gifts/" + giftId + "/links/");
    giftsRef.child(linkId).remove();
    dispatch(removeLinkSuccess(giftId, linkId));
  };
}
export const removeGiftStart = () => ({
  type: REMOVE_GIFT_START,
});
export const removeGiftSuccess = (giftId, gift, userIsOwner) => ({
  type: REMOVE_GIFT_SUCCESS,
  payload: { giftId, gift, userIsOwner },
});
export function removeGift(giftId, gift) {
  return (dispatch, getState) => {
    dispatch(removeGiftStart());
    const giftsRef = getState().firebase.db.ref("gifts/");
    giftsRef.child(giftId).remove();
    const userIsOwner = gift.owner === getState().user.uid;
    dispatch(removeGiftSuccess(giftId, gift, userIsOwner));
  };
}
export const removeConnectionStart = () => ({
  type: REMOVE_CONNECTION_START,
});
export const removeConnectionSuccess = (connectionId) => ({
  type: REMOVE_CONNECTION_SUCCESS,
  payload: { connectionId },
});
export function removeConnection(connectionId) {
  return (dispatch, getState) => {
    dispatch(removeGiftStart());
    const giftsRef = getState().firebase.db.ref(
      "users/" + getState().user.uid + "/connections"
    );
    giftsRef.child(connectionId).remove();
    dispatch(removeGiftSuccess(connectionId));
  };
}

/*Questions*/
export function emailUser(user, subject, text, html) {
  return (dispatch, getState) => {
    const mail = getState().firebase.firestore.collection("mail");
    const payload = {
      to: [user.email],
      message: {
        subject,
        text,
        html,
      },
    };
    mail.add(payload);
  };
}

export const sendQuestionStart = () => ({
  type: QUESTION_SEND_START,
});
export const sendQuestionSuccess = (payload) => ({
  type: QUESTION_SEND_SUCCESS,
  payload,
});
export function sendQuestion(questionTo, question) {
  return (dispatch, getState) => {
    dispatch(sendQuestionStart());

    //actually add the question
    const user = getState().user.uid;
    const questions = getState().firebase.db.ref("questions");
    var payload = {
      questionText: question,
      questionFrom: user,
      questionTo,
      //giftRef: undefined, //todo: add the option to link to a gift
    };
    dispatch(createGiftBegin());
    var newQuestionRef = questions.push(payload, function (error) {
      if (error) {
        dispatch(setError(error));
      } else {
        payload.id = newQuestionRef.key;
        dispatch(sendQuestionSuccess(payload));

        const subject = "Someone has asked you a question on Life of an Elf";
        const text = "Question: " + question;
        const html = "Question: " + question;
        const questionToUser = getState().users.find(function (user) {
          return user.uid === questionTo;
        });
        dispatch(emailUser(questionToUser, subject, text, html));
      }
    });
  };
}

export const getQuestionsStart = () => ({
  type: GET_QUESTIONS_START,
});
export const getQuestionsSuccess = (questions) => ({
  type: GET_QUESTIONS_SUCCESS,
  payload: questions,
});
export function getQuestions() {
  return (dispatch, getState) => {
    //get to-buy list for user
    dispatch(getQuestionsStart());
    const questionsRef = getState().firebase.db.ref("questions");
    questionsRef.on("value", (snapshot) => {
      const questionsObject = snapshot.val();
      dispatch(getQuestionsSuccess(questionsObject));
      questionsRef.off();
    });
  };
}
