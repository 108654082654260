import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navigation from "../Navigation";
import LandingPage from "../Landing";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import HomePage from "../Home";
import WishlistPage from "../Wishlist";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import ShoppingCart from "../ShoppingCart";
import PeoplePage from "../Connections";
import Spinner from "../Spinner";
import ErrorModal from "../ErrorModal";
import NotificationsPage from "../Notifications";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import { connect } from "react-redux";
import { dismissError } from "../../modules/actions/actions";

class App extends Component {
  render() {
    const auth = this.props.user && this.props.user.uid;
    return (
      <div className="col-md-10" style={{ margin: "auto" }}>
        <Router>
          <div className="bg-light">
            <Navigation />

            <div className="container app-container">
              <Spinner></Spinner>
              {auth ? (
                <>
                  <Route path={ROUTES.HOME} component={HomePage} />
                  <Route path={ROUTES.WISHLIST} component={WishlistPage} />
                  <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                  <Route path={ROUTES.ADMIN} component={AdminPage} />
                  <Route path={ROUTES.SHOPPING_CART} component={ShoppingCart} />
                  <Route path={ROUTES.PEOPLE} component={PeoplePage} />
                  <Route
                    path={ROUTES.NOTIFICATIONS}
                    component={NotificationsPage}
                  />
                </>
              ) : (
                <>
                  <Route path={ROUTES.HOME} component={HomePage} />
                  <Route exact path={ROUTES.LANDING} component={LandingPage} />
                  <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                  <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                  <Route
                    path={ROUTES.PASSWORD_FORGET}
                    component={PasswordForgetPage}
                  />
                </>
              )}
              <ErrorModal
                error={this.props.error}
                closeModal={this.props.dismissError}
                showModal={this.props.error !== null}
              />
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  error: state.error
});
const mapDispatchToProps = {
  dismissError
};

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(App)
);
