import {
  //wishlist page
  SAVE_GIFT_SUCCESS,
  CREATE_GIFT_SUCCESS,
  CREATE_NEW_LINK,
  //people page
  SET_BOUGHT_OR_BUYER_SUCCESS,
  //shopping cart page
  GET_CART_SUCCESS,
  //remove items
  REMOVE_BOUGHT_SUCCESS,
  REMOVE_BUYING_SUCCESS,
  REMOVE_GIFT_SUCCESS,
  //set given
  SET_GIVEN_SUCCESS
} from "../actions/actions";

const initialState = {};

function shoppingCartReducer(state = initialState, action) {
  //some things that are used in multiple places
  var newLinks, newState;
  switch (action.type) {
    case CREATE_GIFT_SUCCESS:
      if (!action.payload.userIsOwner) {
        //gift belongs to another user, updating shopping list
        newState = { ...state };
        if (state && action.payload.buying) {
          //item is in shopping cart, update
          newState[action.payload.gift.id] = action.payload.gift;
        }
        return newState;
      }
      return state;
    case SAVE_GIFT_SUCCESS:
      if (!action.payload.userIsOwner) {
        //gift belongs to another user, updating shopping list or connection
        newState = { ...state };
        if (state && state[action.payload.giftId]) {
          //item is in shopping cart, update
          newState[action.payload.giftId] = action.payload.gift;
        }
        return newState;
      }
      return state;

    case CREATE_NEW_LINK:
      if (!action.payload.userIsOwner) {
        newState = { ...state };
        ///we've added a new link for someone else.  check if it's on the shopping list
        if (state[action.payload.giftId]) {
          //add links in shopping cart
          newLinks = {
            ...newState[action.payload.giftId].links
          };
          newLinks[action.payload.linkId] = action.payload.link;
          newState[action.payload.giftId].links = newLinks;
        }
        return newState;
      }
      return state;
    case SET_BOUGHT_OR_BUYER_SUCCESS:
      newState = { ...state };
      newState[action.payload.itemId] = action.payload.item;

      return newState;
    //SHOPPING CART PAGE
    case GET_CART_SUCCESS:
      const filteredCart = {};
      if (action.payload.cart) {
        Object.keys(action.payload.cart).forEach(giftKey => {
          var cartItem = action.payload.cart[giftKey];
          if (!(cartItem.givenStatus === "GIVEN")) {
            filteredCart[giftKey] = cartItem;
          }
        });
      }
      return filteredCart;
    //REMOVE ITEMS
    case REMOVE_BOUGHT_SUCCESS:
      newState = { ...state };
      if (state && state[action.payload.giftId]) {
        delete newState[action.payload.giftId].boughtStatus;
      }
      return newState;

    case REMOVE_BUYING_SUCCESS:
      newState = { ...state };
      if (state && state[action.payload.giftId]) {
        delete newState[action.payload.giftId];
      }
      return newState;

    case REMOVE_GIFT_SUCCESS:
      if (!action.payload.userIsOwner) {
        //it's owned by someone else
        newState = { ...state };
        if (state && state[action.payload.giftId]) {
          //it's in the shopping cart, remove it
          delete newState[action.payload.giftId];
        }
        return newState;
      }
      return state;
    case SET_GIVEN_SUCCESS:
      if (!action.payload.userIsOwner) {
        //it's owned by someone else
        newState = { ...state };
        if (state && state[action.payload.giftId]) {
          //it's in the shopping cart, remove it
          delete newState[action.payload.giftId];
        }
        return newState;
      }
      return state;

    //DEFAULT
    default:
      return state;
  }
}

export default shoppingCartReducer;
