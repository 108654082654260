import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  LOGOUT,
  FETCH_WISHLIST_SUCCESS,
  //wishlist page
  SAVE_GIFT_BEGIN,
  SAVE_GIFT_SUCCESS,
  CREATE_GIFT_BEGIN,
  CREATE_GIFT_SUCCESS,
  //people page
  GET_CONNECTION_GIFTS_BEGIN,
  GET_CONNECTION_GIFTS_SUCCESS,
  SAVE_CONNECTION_START,
  SAVE_CONNECTION_SUCCESS,
  SET_BUYER_START,
  SET_BOUGHT_OR_BUYER_SUCCESS,
  SET_BOUGHT_START,
  //shopping cart page
  GET_CART_START,
  GET_CART_SUCCESS,
  //remove items
  REMOVE_BOUGHT_START,
  REMOVE_BOUGHT_SUCCESS,
  REMOVE_BUYING_START,
  REMOVE_BUYING_SUCCESS,
  REMOVE_GIFT_START,
  REMOVE_GIFT_SUCCESS,
  REMOVE_LINK_START,
  REMOVE_LINK_SUCCESS,
  REMOVE_CONNECTION_START,
  REMOVE_CONNECTION_SUCCESS,
  //set given
  SET_GIVEN_START,
  SET_GIVEN_SUCCESS,
  //questions
  QUESTION_SEND_SUCCESS,
  QUESTION_ANSWER_SUCCESS,
} from "../actions/actions";

const initialState = false;

function loadingReducer(state = initialState, action) {
  switch (action.type) {
    //INITIALIZATION STUFF
    case FETCH_USERS_BEGIN:
    case CREATE_GIFT_BEGIN:
    case SAVE_GIFT_BEGIN:
    case GET_CONNECTION_GIFTS_BEGIN:
    case SAVE_CONNECTION_START:
    case SET_BUYER_START:
    case SET_BOUGHT_START:
    case GET_CART_START:
    case REMOVE_BOUGHT_START:
    case REMOVE_BUYING_START:
    case REMOVE_GIFT_START:
    case REMOVE_LINK_START:
    case REMOVE_CONNECTION_START:
    case SET_GIVEN_START:
      return true;
    case FETCH_USERS_SUCCESS:
    case FETCH_WISHLIST_SUCCESS:
    case LOGOUT:
    case CREATE_GIFT_SUCCESS:
    case SAVE_GIFT_SUCCESS:
    case GET_CONNECTION_GIFTS_SUCCESS:
    case SAVE_CONNECTION_SUCCESS:
    case SET_BOUGHT_OR_BUYER_SUCCESS:
    case GET_CART_SUCCESS:
    case REMOVE_BOUGHT_SUCCESS:
    case REMOVE_BUYING_SUCCESS:
    case REMOVE_GIFT_SUCCESS:
    case REMOVE_LINK_SUCCESS:
    case REMOVE_CONNECTION_SUCCESS:
    case SET_GIVEN_SUCCESS:
    case QUESTION_ANSWER_SUCCESS:
    case QUESTION_SEND_SUCCESS:
      return false;

    //DEFAULT
    default:
      return state;
  }
}

export default loadingReducer;
