import React, { Component } from "react";
import Edit from "../SVGs/edit";
import "./giftDisplay.scss";
import WarnGivenModal from "./warnGivenModal";
import { connect } from "react-redux";
import { setGiven } from "../../modules/actions/actions";
const BOUGHT = "BOUGHT";

/** Gift Display
 * requires props:
 * - item
 * - itemId
 * - userId (current user)
 * - handleSetBuyer
 * - handleSetBought
 * optional:
 * - handleEdit
 * - handleDelete
 */
class GiftDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGivenWarning: false,
    };
  }
  shouldComponentUpdate = (props) => {
    return true;
  };

  handleSetBuyer = () => {
    this.props.handleSetBuyer(
      this.props.item,
      this.props.itemId,
      this.props.userId
    );
  };
  handleSetBought = () => {
    this.props.handleSetBought(this.props.item, this.props.itemId, BOUGHT);
  };
  handleEdit = () => {
    this.props.onEdit && this.props.onEdit(this.props.item, this.props.itemId);
  };
  handleRemoveBuyer = () => {
    this.props.handleRemoveBuyer(this.props.itemId);
  };
  handleRemoveBought = () => {
    this.props.handleRemoveBought(this.props.itemId);
  };
  handleDelete = () => {
    this.props.handleDelete &&
      this.props.handleDelete(this.props.itemId, this.props.item);
  };
  handleRemoveReviewClose = () => {
    this.setState({ showReviewRemoveConnection: false });
  };
  // FUNCTIONS TO MARK GIFT AS GIVEN
  handleWarnGiven = () => {
    //pop modal
    this.setState({ showGivenWarning: true });
  };
  handleCancelGiven = () => {
    //un-pop modal
    this.setState({ showGivenWarning: false });
  };
  handleSetGiven = () => {
    this.setState({ showGivenWarning: false });
    this.props.setGiven &&
      this.props.setGiven(this.props.item, this.props.itemId);
  };

  // FUNCTION TO SET BUYING
  getBuying = () => {
    return this.props.item && this.props.item.buyer === this.props.userId;
  };
  getUrl = (url) => {
    const urlRegex = /^http/g;
    if (url && url.match(urlRegex)) {
      return url;
    }
    return "//" + url;
  };
  renderRemoveButton = (myItem, bought) => {
    if (!myItem) {
      return "";
    } else if (bought) {
      return (
        <button className="btn-link" onClick={this.handleRemoveBought}>
          I didn't buy it
        </button>
      );
    } else if (this.props.item && this.props.item.privacy !== "PRIVATE") {
      return (
        <button className="btn-link" onClick={this.handleRemoveBuyer}>
          Remove from buy list
        </button>
      );
    } else {
      return "";
    }
  };
  render() {
    const item = this.props.item ? this.props.item : {};
    const myItem = this.getBuying();
    const claimed = item.buyer !== undefined && item.buyer !== null;
    const bought = item.boughtStatus === BOUGHT;
    //const linkKeys = item && item.links ? Object.keys(item.links) : [];
    const isOwner = item && item.owner === this.props.userId;
    const isCreator = item && item.creator === this.props.userId;
    return (
      <div className="gift-display-container">
        {/* HEADER CODE */}
        <div className="gift-header-row">
          <div className="gift-title-description">
            {this.props.showTitle && (
              <div className="gift-title">{item.title}</div>
            )}
            <div className="gift-description">{item.description}</div>
          </div>
          {(isOwner || isCreator) && (
            <div className="gift-edit-button">
              <button
                className="btn edit-button-icon-container"
                onClick={this.handleEdit}
              >
                <Edit />
              </button>
            </div>
          )}
        </div>
        {/* BODY CODE */}
        <div className="gift-body">
          <div className="col-md-6 gift-body-column">
            <div className="gift-property-title">Links</div>
            <div className="gifts-links-list">
              {this.props.item && this.props.item.links ? (
                <ul>
                  {Object.keys(this.props.item.links).map((key) => {
                    return (
                      <li key={key}>
                        <a
                          href={this.getUrl(this.props.item.links[key].url)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.props.item.links[key].text
                            ? this.props.item.links[key].text
                            : this.props.item.links[key].url}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                "N/A"
              )}
            </div>
          </div>
          <div className="col-md-6 gift-body-column">
            <div className="gift-property">
              <span className="gift-property-title">Size:</span>
              <span className="gift-property-contents">
                {" "}
                {item.size ? item.size : "N/A"}{" "}
              </span>
            </div>
            <div className="gift-property">
              <span className="gift-property-title">Color:</span>
              <span className="gift-property-contents">
                {" "}
                {item.color ? item.color : "N/A"}{" "}
              </span>
            </div>
            <div className="gift-property">
              <span className="gift-property-title">Price:</span>
              <span className="gift-property-contents">
                {" "}
                {item.price !== undefined ? item.price : "N/A"}{" "}
              </span>
            </div>
          </div>
        </div>
        {/* FOOTER CODE */}
        {this.props.isOwner || this.props.isPastItem ? (
          ""
        ) : (
          <div className="gift-footer">
            <div className="gift-footer-column col-md-6">
              {!myItem && (
                <>
                  <button
                    className="btn btn-success"
                    disabled={claimed}
                    onClick={this.handleSetBuyer}
                  >
                    I will buy this.
                  </button>
                  {claimed && (
                    <span> It looks like someone is buying this already</span>
                  )}
                </>
              )}
              {myItem && !bought && (
                <>
                  <button
                    className="btn btn-success"
                    disabled={bought}
                    onClick={this.handleSetBought}
                  >
                    Shopping done!
                  </button>
                  {bought && <span>You already bought this</span>}
                </>
              )}
              {bought && (
                <div className="gift-given-button">
                  <button
                    className="btn btn-success"
                    onClick={this.handleWarnGiven}
                  >
                    I gave this
                  </button>
                </div>
              )}
            </div>
            <div className="gift-footer-column gift-footer-right col-md-6">
              {this.renderRemoveButton(myItem, bought)}
            </div>
          </div>
        )}
        {isOwner && !this.props.isPastItem && (
          <div className="gift-given-button">
            <button
              className="btn btn-success"
              onClick={this.handleWarnGiven}
              disabled={item.givenStatus === "GIVEN"}
            >
              Mark this item as gifted
            </button>
          </div>
        )}
        {(isOwner || isCreator) && !this.props.isPastItem && (
          <div className="gift-delete-button">
            <button className="btn btn-danger" onClick={this.handleDelete}>
              Delete item
            </button>
          </div>
        )}
        {isOwner && this.props.isPastItem && (
          <div className="gift-footer">
            <div className="gift-footer-column">
              <button className="btn-link" onClick={this.handleGetAgain}>
                I want this again
              </button>
            </div>
          </div>
        )}
        {this.state.showGivenWarning && (
          <WarnGivenModal
            show={this.state.showGivenWarning}
            closeModal={this.handleCancelGiven}
            confirmGiven={this.handleSetGiven}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setGiven,
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftDisplay);
