import {
  LOGOUT,
  FINISH_AUTHENTICATION,
  FETCH_WISHLIST_SUCCESS,
  FETCH_CONNECTIONS,
  //wishlist page
  CLICK_WISHLIST_ITEM,
  SAVE_GIFT_SUCCESS,
  CREATE_GIFT_SUCCESS,
  CREATE_NEW_LINK,
  //people page
  SAVE_CONNECTION_SUCCESS,
  //remove items
  REMOVE_GIFT_SUCCESS,
  REMOVE_LINK_SUCCESS,
  REMOVE_CONNECTION_SUCCESS,
  //set given
  SET_GIVEN_SUCCESS
} from "../actions/actions";

const initialState = {
  wishlist: null,
  pastWishlist: null,
  connections: {}
};

function userReducer(state = initialState, action) {
  //some things that are used in multiple places
  var isOwner, newGifts, newLinks;
  switch (action.type) {
    case FETCH_CONNECTIONS:
      const userConnections = {};
      if (action.payload.connections) {
        const connectionKeys = Object.keys(action.payload.connections);
        for (var i = 0; i < connectionKeys.length; i++) {
          const connection = action.payload.connections[connectionKeys[i]];
          userConnections[connection.connectionId] = true;
        }
      }
      return {
        ...state,
        connections: userConnections
      };
    case FINISH_AUTHENTICATION:
      return {
        ...action.payload.user,
        connections: {}
      };
    case FETCH_WISHLIST_SUCCESS:
      var wishlist = {};
      var pastWishlist = {};
      if (action.payload.wishlist) {
        Object.keys(action.payload.wishlist).forEach(giftKey => {
          var gift = action.payload.wishlist[giftKey];
          if (
            !(
              gift.creator &&
              gift.creator !== gift.owner &&
              gift.privacy &&
              gift.privacy !== "OWNER"
            )
          ) {
            gift.givenStatus !== "GIVEN"
              ? (wishlist[giftKey] = gift)
              : (pastWishlist[giftKey] = gift);
          }
        });
      }
      const wishlistKey = Object.keys(wishlist)[0];
      return {
        ...state,
        wishlist: wishlist,
        pastWishlist: pastWishlist,
        wishlistKey: wishlistKey
      };
    case LOGOUT:
      return {
        wishlist: null,
        connections: {}
      };
    //WISHLIST PAGE STUFF
    case CLICK_WISHLIST_ITEM:
      return {
        ...state,
        wishlistKey: action.payload.key
      };
    case CREATE_GIFT_SUCCESS:
      isOwner = action.payload.giftOwner === state.uid;
      if (isOwner) {
        //user is owner, update wishlist
        const newWishlist = { ...state.wishlist };
        newWishlist[action.payload.gift.id] = action.payload.gift;
        return {
          ...state,
          wishlist: newWishlist,
          wishlistKey: action.payload.gift.id
        };
      } else {
        return state;
      }

    case SAVE_GIFT_SUCCESS:
      isOwner = action.payload.giftOwner === state.uid;
      if (isOwner) {
        //user is owner, update wishlist
        const updatedWishlist = { ...state.wishlist };
        updatedWishlist[action.payload.giftId] = action.payload.gift;
        return {
          ...state,
          wishlist: updatedWishlist
        };
      } else {
        return state;
      }

    case CREATE_NEW_LINK:
      //is user the owner?
      isOwner = action.payload.giftOwner === state.uid;
      //user is owner, add it to my wishlist
      if (isOwner) {
        newGifts = { ...state.wishlist };
        newLinks = { ...newGifts[action.payload.giftId].links };
        newLinks[action.payload.linkId] = action.payload.link;
        newGifts[action.payload.giftId].links = newLinks;
        return {
          ...state,
          wishlist: newGifts
        };
      } else {
        return state;
      }
    case SAVE_CONNECTION_SUCCESS:
      const connections = { ...state.connections };
      connections[action.payload.connection] = true;
      return {
        ...state,
        connections: connections
      };

    case REMOVE_GIFT_SUCCESS:
      isOwner = action.payload.gift.owner === state.uid;
      if (isOwner) {
        //user owns gift, remove from wishlist
        const removeGiftWishlist = { ...state.wishlist };
        delete removeGiftWishlist[action.payload.giftId];
        return {
          ...state,
          wishlist: removeGiftWishlist
        };
      } else {
        return state;
      }

    case REMOVE_LINK_SUCCESS:
      const removeLinkWishlist = { ...state.wishlist };
      const removeLinkGift = removeLinkWishlist[action.payload.giftId];
      delete removeLinkGift.connections[action.payload.linkId];
      return {
        ...state,
        wishlist: removeLinkWishlist
      };
    case REMOVE_CONNECTION_SUCCESS:
      const removeConnections = { ...state.connections };
      delete removeConnections[action.payload.connectionId];
      return {
        ...state,
        connections: removeConnections
      };
    case SET_GIVEN_SUCCESS:
      isOwner = action.payload.gift.owner === state.uid;
      const givenItem = { ...action.payload.gift, given: "GIVEN" };
      if (isOwner) {
        //user owns gift, remove from wishlist
        const givenGiftWishlist = { ...state.wishlist };
        const givenPastWishlist = { ...state.pastWishlist };
        delete givenGiftWishlist[action.payload.giftId];
        givenPastWishlist[action.payload.giftId] = givenItem;
        return {
          ...state,
          wishlist: givenGiftWishlist,
          pastWishlist: givenPastWishlist
        };
      } else {
        return state;
      }
    //DEFAULT
    default:
      return state;
  }
}

export default userReducer;
