import {
  LOGOUT,
  //wishlist page
  SAVE_GIFT_SUCCESS,
  CREATE_GIFT_SUCCESS,
  CREATE_NEW_LINK,
  //people page
  GET_CONNECTION_GIFTS_SUCCESS,
  SAVE_CONNECTION_SUCCESS,
  SET_BOUGHT_OR_BUYER_SUCCESS,
  //remove items
  REMOVE_BOUGHT_SUCCESS,
  REMOVE_BUYING_SUCCESS,
  REMOVE_GIFT_SUCCESS,
  //set given
  SET_GIVEN_SUCCESS
} from "../actions/actions";

const initialState = null;

function currentConnectionReducer(state = initialState, action) {
  //some things that are used in multiple places
  var newLinks, newConnectionWishlist, newState;
  switch (action.type) {
    case LOGOUT:
      return null;
    //WISHLIST PAGE STUFF
    case CREATE_GIFT_SUCCESS:
      //check if it could be current connection
      if (!action.payload.userIsOwner) {
        if (
          state !== null &&
          state.wishlist &&
          state.userId === action.payload.gift.owner
        ) {
          //item is in current connection, update
          newConnectionWishlist = { ...state.wishlist };
          newConnectionWishlist[action.payload.gift.id] = action.payload.gift;
        }
        return {
          ...state,
          wishlist: newConnectionWishlist
        };
      }
      return state;

    case SAVE_GIFT_SUCCESS:
      if (!action.payload.userIsOwner) {
        //gift belongs to another user, updating shopping list or connection
        if (
          state !== null &&
          state.wishlist &&
          state.wishlist[action.payload.giftId]
        ) {
          //item is in current connection, update
          newConnectionWishlist = { ...state.wishlist };
          newConnectionWishlist[action.payload.giftId] = action.payload.gift;
        }
        return {
          ...state,
          wishlist: newConnectionWishlist
        };
      }
      return state;

    case CREATE_NEW_LINK:
      if (!action.payload.userIsOwner) {
        //check if currentConnection needs updated
        if (state && state.wishlist && state.wishlist[action.payload.giftId]) {
          //add links to object in current connection
          newConnectionWishlist = { ...state.wishlist };
          newLinks = {
            ...state.wishlist[action.payload.giftId].links
          };
          newLinks[action.payload.linkId] = action.payload.link;
          newConnectionWishlist[action.payload.giftId].links = newLinks;
          return {
            ...state,
            wishlist: newConnectionWishlist
          };
        }
      }
      return state;
    case GET_CONNECTION_GIFTS_SUCCESS:
      var connectionWishlist = {};
      if (action.payload.wishlist) {
        Object.keys(action.payload.wishlist).forEach(giftKey => {
          var connectionGift = action.payload.wishlist[giftKey];
          if (
            !(
              (connectionGift.creator !== action.payload.userId &&
                connectionGift.privacy === "PRIVATE") ||
              connectionGift.givenStatus === "GIVEN"
            )
          ) {
            connectionWishlist[giftKey] = connectionGift;
          }
        });
      }
      return {
        userId: action.payload.user.uid,
        username: action.payload.user.username,
        wishlist: connectionWishlist
      };
    case SAVE_CONNECTION_SUCCESS:
      return null;
    case SET_BOUGHT_OR_BUYER_SUCCESS:
      if (action.payload.setConnectionWishlist) {
        const newConnectionWishlist = {
          ...state.wishlist
        };
        newConnectionWishlist[action.payload.itemId] = action.payload.item;
        return {
          ...state,
          wishlist: newConnectionWishlist
        };
      }
      return state;
    //REMOVE ITEMS
    case REMOVE_BOUGHT_SUCCESS:
      const removeBoughtConnection = {
        ...state
      };
      if (state && state.wishlist && state.wishlist[action.payload.giftId]) {
        delete removeBoughtConnection.wishlist[action.payload.giftId]
          .boughtStatus;
      }
      return removeBoughtConnection;
    case REMOVE_BUYING_SUCCESS:
      const removeBuyingConnection = {
        ...state
      };
      if (state && state.wishlist && state.wishlist[action.payload.giftId]) {
        delete removeBuyingConnection.wishlist[action.payload.giftId].buyer;
      }
      return removeBuyingConnection;
    case REMOVE_GIFT_SUCCESS:
      if (!action.payload.userIsOwner) {
        //it's owned by someone else
        newState = { ...state };
        if (state && state.wishlist && state.wishlist[action.payload.giftId]) {
          //it's in the current connection wishlist, remove it
          delete newState.wishlist[action.payload.giftId];
        }
        return newState;
      }
      return state;
    case SET_GIVEN_SUCCESS:
      if (!action.payload.userIsOwner) {
        //it's owned by someone else
        newState = { ...state };
        if (state && state.wishlist && state.wishlist[action.payload.giftId]) {
          //it's in the current connection wishlist, remove it
          delete newState.wishlist[action.payload.giftId];
        }
        return newState;
      }
      return state;

    //DEFAULT
    default:
      return state;
  }
}

export default currentConnectionReducer;
