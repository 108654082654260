import {
  QUESTION_SEND_SUCCESS,
  QUESTION_ANSWER_SUCCESS,
  GET_QUESTIONS_SUCCESS,
} from "../actions/actions";

const initialState = {};

function questionsReducer(state = initialState, action) {
  var newState;
  switch (action.type) {
    //get questions
    case GET_QUESTIONS_SUCCESS:
      newState = { ...action.payload };
      return newState;
    //send or answer a question
    case QUESTION_SEND_SUCCESS:
    case QUESTION_ANSWER_SUCCESS:
      newState = { ...state };
      newState[action.payload.id] = action.payload;
      console.log(newState);
      return newState;
    //DEFAULT
    default:
      return state;
  }
}

export default questionsReducer;
