import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withFirebase } from "../Firebase";

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE, showModal: true });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onDismissModal = () => {
    this.setState({
      showModal: false
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne.length < 6;
    const pswdOneValid = passwordOne.length >= 6;
    const pswdTwoValid = passwordTwo === passwordOne;

    return (
      <>
        <form className="form-inline" onSubmit={this.onSubmit}>
          <div className="form-group mb-2">
            <label htmlFor="password1" className="sr-only">
              Password
            </label>
            <input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              placeholder="New password"
              type="password"
              className={
                pswdOneValid ? "form-control" : "form-control is-invalid"
              }
              id="password1"
            />
          </div>

          <div className="form-group mx-sm-3 mb-2">
            <label htmlFor="password2" className="sr-only">
              Password
            </label>
            <input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              placeholder="Confirm new password"
              type="password"
              className={
                pswdTwoValid ? "form-control" : "form-control is-invalid"
              }
              id="password2"
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary mb-2"
            disabled={isInvalid}
          >
            Reset My Password
          </button>
        </form>
        {!pswdOneValid ? (
          <div className="invalid-feedback d-block">
            Password should be at least 6 characters long
          </div>
        ) : !pswdTwoValid ? (
          <div className="invalid-feedback d-block">Passwords should match</div>
        ) : (
          ""
        )}
        {this.state.error ? <span>{this.state.error.message}</span> : ""}
        <ConfirmationModal
          show={this.state.showModal}
          closeModal={this.onDismissModal}
        />
      </>
    );
  }
}

class ConfirmationModal extends Component {
  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Password Change Successful</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Dismiss
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withFirebase(PasswordChangeForm);
