/*import {
  LOGOUT,
  FINISH_AUTHENTICATION,
  FETCH_WISHLIST_SUCCESS,
  FETCH_CONNECTIONS,
  //wishlist page
  CLICK_WISHLIST_ITEM,
  SAVE_GIFT_BEGIN,
  SAVE_GIFT_SUCCESS,
  CREATE_GIFT_SUCCESS,
  CREATE_NEW_LINK,
  //people page
  GET_CONNECTION_GIFTS_SUCCESS,
  SAVE_CONNECTION_SUCCESS,
  SET_BOUGHT_OR_BUYER_SUCCESS,
  //shopping cart page
  GET_CART_SUCCESS,
  //remove items
  REMOVE_BOUGHT_SUCCESS,
  REMOVE_BUYING_SUCCESS,
  REMOVE_GIFT_SUCCESS,
  REMOVE_LINK_SUCCESS,
  REMOVE_CONNECTION_SUCCESS,
  //set given
  SET_GIVEN_SUCCESS
} from "../actions/actions";

const initialState = {
  shoppingCart: {}
};*/

import { combineReducers } from "redux";
import users from "./usersReducer";
import loading from "./loadingReducer";
import error from "./errorReducer";
import firebase from "./firebaseReducer";
import currentConnection from "./currentConnectionReducer";
import shoppingCart from "./shoppingCartReducer";
import user from "./userReducer";
import questions from "./questionsReducer";

export default combineReducers({
  users,
  loading,
  error,
  firebase,
  currentConnection,
  shoppingCart,
  user,
  questions,
});
