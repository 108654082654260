import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class RemoveConnectionModal extends Component {
  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Remove connection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Honestly, this is really complicated. It's not coded yet.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            {/*<Button
              variant="primary"
              onClick={this.handleSave}
              disabled={disabledSave}
            >
              Save Connection
            </Button>*/}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default RemoveConnectionModal;
