import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { connect } from "react-redux";
import { firebaseLogout } from "../../modules/actions/actions";

class SignOutButton extends Component {
  render() {
    return (
      <button
        className="btn btn-outline-success my-2 my-sm-0"
        type="button"
        onClick={this.props.firebaseLogout}
      >
        Sign Out
      </button>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  firebaseLogout
};

export default withFirebase(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignOutButton)
);
