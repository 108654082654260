import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./editGift.scss";
import RemoveIcon from "../SVGs/removeIcon";
import AddIcon from "../SVGs/addIcon";

const blankItem = {
  id: "",
  title: "",
  description: ""
};
const PRIVACY_OPTIONS = {
  OWNER: "Owner can see (default)",
  USERS: "Other users can see",
  PRIVATE: "Only I can see this"
};
class ItemModal extends Component {
  constructor(props) {
    super(props);

    var firstOwner = this.props.owner;
    var connectionKeys = [];
    if (!firstOwner && this.props.connections) {
      connectionKeys = Object.keys(this.props.connections);
      firstOwner = connectionKeys.length ? connectionKeys[0] : undefined;
    }
    this.state = {
      item: { ...this.props.item },
      ...this.props.newItem,
      owner: firstOwner,
      connectionKeys: connectionKeys,
      newLinks: {}
    };
  }

  handleClose = () => {
    this.setState({ ...blankItem });
    this.props.closeModal();
  };
  handleSave = () => {
    this.props.saveModal(
      this.state.item,
      this.state.newLinks,
      this.state.owner
    );
  };

  //FUNCTIONS TO CHANGE INPUTS
  onTitleChange = e => {
    this.setState({ item: { ...this.state.item, title: e.target.value } });
  };
  onDescriptionChange = e => {
    this.setState({
      item: { ...this.state.item, description: e.target.value }
    });
  };
  onSizeChange = e => {
    this.setState({ item: { ...this.state.item, size: e.target.value } });
  };
  onColorChange = e => {
    this.setState({ item: { ...this.state.item, color: e.target.value } });
  };
  onPriceChange = e => {
    this.setState({ item: { ...this.state.item, price: e.target.value } });
  };
  //LINK CHANGES
  onCurrentUrlChange = (e, key) => {
    const newlinks = {
      ...this.state.item.links
    };
    newlinks[key] = {
      ...this.state.item.links[key],
      url: e.target.value
    };
    this.setState({ item: { ...this.state.item, links: newlinks } });
  };
  onCurrentUrlDisplayChange = (e, key) => {
    const newlinks = {
      ...this.state.item.links
    };
    newlinks[key] = {
      ...this.state.item.links[key],
      text: e.target.value
    };
    this.setState({ item: { ...this.state.item, links: newlinks } });
  };
  onNewUrlChange = (e, key) => {
    const newlinks = {
      ...this.state.newLinks
    };
    newlinks[key] = {
      ...this.state.newLinks[key],
      url: e.target.value
    };
    this.setState({ newLinks: newlinks });
  };
  onNewUrlDisplayChange = (e, key) => {
    const newlinks = {
      ...this.state.newLinks
    };
    newlinks[key] = {
      ...this.state.newLinks[key],
      text: e.target.value
    };
    this.setState({ newLinks: newlinks });
  };
  removeExistingUrl = key => {
    const newLinks = { ...this.state.item.links };
    delete newLinks[key];
    this.setState({ item: { ...this.state.item, links: newLinks } });
  };
  removeNewUrl = key => {
    const newLinks = { ...this.state.newLinks };
    delete newLinks[key];
    this.setState({ newLinks: newLinks });
  };
  //ADD A URL
  onUrlAdd = () => {
    const newLinkKeys = this.state.newLinks
      ? Object.keys(this.state.newLinks)
      : [];
    var newLinksNum = newLinkKeys.length ? newLinkKeys.slice(-1).pop() : 0;
    newLinksNum = newLinksNum === undefined ? 0 : Number(newLinksNum) + 1;
    const newLinks = { ...this.state.newLinks };
    newLinks[newLinksNum] = { url: "", text: "" };
    this.setState({ newLinks: newLinks });
  };
  //OWNER CHANGES
  onSelectOwner = e => {
    this.setState({
      owner: e.target.value
    });
  };
  onPrivacyChange = e => {
    this.setState({ item: { ...this.state.item, privacy: e.target.value } });
  };

  validateSave = () => {
    return !this.state.item.title;
  };
  findUserForId = id => {
    return this.props.users.find(user => {
      return user.uid === id;
    });
  };

  render() {
    const { title, description, links } = this.state.item;
    const titleCol = "col-sm-3 col-form-label";
    const descCol = "col-sm-9";
    const linkKeys = links ? Object.keys(links) : [];
    const newLinkKeys = this.state.newLinks
      ? Object.keys(this.state.newLinks)
      : [];
    const { connectionKeys } = this.props;
    return (
      <>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              {this.state.owner && (
                <div className="form-group row">
                  <label htmlFor="title" className={titleCol}>
                    It's for:
                  </label>
                  <div className={descCol}>
                    {connectionKeys && connectionKeys.length > 1 ? (
                      <select
                        className="custom-select"
                        value={this.state.owner}
                        onChange={this.handleOwnerChange}
                      >
                        {connectionKeys.map(connection => (
                          <option value={connection} key={connection}>
                            {this.findUserForId(connection).username}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        readOnly
                        className="form-control-plaintext"
                        id="staticOwner"
                        value={this.findUserForId(this.state.owner).username}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="form-group row">
                <label htmlFor="title" className={titleCol}>
                  Title
                </label>
                <div className={descCol}>
                  <input
                    type="text"
                    className={
                      this.validateSave()
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    id="title"
                    value={title || ""}
                    onChange={this.onTitleChange}
                    required
                  />
                  <div className="invalid-feedback">Title cannot be empty</div>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="inputPassword" className={titleCol}>
                  Description
                </label>
                <div className={descCol}>
                  <textarea
                    type="text"
                    className="form-control"
                    id="inputPassword"
                    value={description || ""}
                    onChange={this.onDescriptionChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="size" className={titleCol}>
                  Size
                </label>
                <div className={descCol}>
                  <input
                    type="text"
                    className="form-control"
                    id="size"
                    value={this.state.item.size || ""}
                    onChange={this.onSizeChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="color" className={titleCol}>
                  Color
                </label>
                <div className={descCol}>
                  <input
                    type="text"
                    className="form-control"
                    id="color"
                    value={this.state.item.color || ""}
                    onChange={this.onColorChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="price" className={titleCol}>
                  Price
                </label>
                <div className={descCol}>
                  <input
                    type="text"
                    className="form-control"
                    id="price"
                    value={this.state.item.price || ""}
                    onChange={this.onPriceChange}
                  />
                </div>
              </div>
              {this.state.owner && (
                <div className="form-group row">
                  <label htmlFor="title" className={titleCol}>
                    Privacy
                  </label>
                  <div className={descCol}>
                    <select
                      className="custom-select"
                      value={this.state.item.privacy}
                      onChange={this.onPrivacyChange}
                    >
                      {Object.keys(PRIVACY_OPTIONS).map(option => (
                        <option value={option} key={option}>
                          {PRIVACY_OPTIONS[option]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {/*LINK DISPLAY*/}
              <b>Links</b>
              <div className="form-group row links-display">
                {/*TITLE ROW*/}
                <div className="link-title-row">
                  <div className="link-titles">
                    <div className="col-sm-6 link-col-display">url</div>
                    <div className="col-sm-6 link-col-display">
                      display text
                    </div>
                  </div>
                  <div className="link-remove-display"></div>
                </div>
                <div className="links-collection">
                  {/*EXISTING LINKS*/}
                  {linkKeys.map(key => (
                    <div className="link-list-container" key={key}>
                      <div className="link-col-display">
                        {links[key].remove ? (
                          <div className="link-remove-text">
                            {links[key].url}
                          </div>
                        ) : (
                          <input
                            className="form-control"
                            value={links[key].url || ""}
                            onChange={e => {
                              this.onCurrentUrlChange(e, key);
                            }}
                          />
                        )}
                      </div>
                      <div className="link-col-display">
                        {links[key].remove ? (
                          <div className="link-remove-text">
                            {links[key].text}
                          </div>
                        ) : (
                          <input
                            className="form-control"
                            value={links[key].text || ""}
                            onChange={e => {
                              this.onCurrentUrlDisplayChange(e, key);
                            }}
                          />
                        )}
                      </div>
                      <Button
                        className="btn btn-light link-remove-display"
                        onClick={() => this.removeExistingUrl(key)}
                      >
                        {links[key].remove ? <AddIcon /> : <RemoveIcon />}
                      </Button>
                    </div>
                  ))}

                  {/*NEWLY ADDED LINKS*/}
                  {newLinkKeys.map((key, index) => (
                    <div className="link-list-container" key={key}>
                      <div className="link-col-display">
                        <input
                          className="form-control"
                          value={this.state.newLinks[key].url || ""}
                          onChange={e => {
                            this.onNewUrlChange(e, key, index);
                          }}
                        />
                      </div>
                      <div className="link-col-display">
                        <input
                          className="form-control"
                          value={this.state.newLinks[key].text || ""}
                          onChange={e => {
                            this.onNewUrlDisplayChange(e, key, index);
                          }}
                        />
                      </div>
                      <Button
                        className="btn btn-light link-remove-display"
                        onClick={() => this.removeNewUrl(key)}
                      >
                        <RemoveIcon />
                      </Button>
                    </div>
                  ))}
                </div>

                {/*ADD ANOTHER LINK*/}
                <div className="link-add-button-container">
                  <Button
                    variant="success"
                    className="add-link-button"
                    onClick={this.onUrlAdd}
                  >
                    add a link
                  </Button>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.handleSave}
              disabled={this.validateSave()}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default ItemModal;
