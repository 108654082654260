import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Navbar, Form } from "react-bootstrap";
import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import { connect } from "react-redux";

class Navigation extends Component {
  render() {
    const auth = this.props.user && this.props.user.uid;
    return (
      <div>
        <Navbar bg="dark" expand="lg" className="navbar-dark bg-dark">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {auth ? <NavigationAuth /> : <NavigationNonAuth />}
            </Nav>
            <Form className="form-inline my-2 my-lg-0">
              <SignOutButton />
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const NavigationAuth = () => (
  <Nav className="mr-auto">
    <Nav.Link as={NavLink} to={ROUTES.HOME} className="nav-link" exact>
      Home
    </Nav.Link>
    <Nav.Link as={NavLink} to={ROUTES.WISHLIST} className="nav-link" exact>
      Wishlist
    </Nav.Link>
    <Nav.Link as={NavLink} to={ROUTES.ACCOUNT} className="nav-link" exact>
      Account
    </Nav.Link>
  </Nav>
);

const NavigationNonAuth = () => (
  <Nav className="mr-auto">
    <Nav.Link as={NavLink} to={ROUTES.LANDING} className="nav-link" exact>
      Landing
    </Nav.Link>
    <Nav.Link as={NavLink} to={ROUTES.SIGN_IN} className="nav-link" exact>
      Sign In
    </Nav.Link>
  </Nav>
);

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Navigation);
