import React from "react";

const CircleCross = () => (
  <svg height="100%" width="100%" viewBox="0 0 100 100">
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke="darkgray"
      strokeWidth="8"
      fill="transparent"
    />
    <line
      x1="20"
      y1="20"
      x2="80"
      y2="80"
      style={{ stroke: "darkgray", strokeWidth: 7 }}
    />
    <line
      x1="80"
      y1="20"
      x2="20"
      y2="80"
      style={{ stroke: "darkgray", strokeWidth: 7 }}
    />
  </svg>
);

export default CircleCross;
