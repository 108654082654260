import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class WarnGivenModal extends Component {
  handleClose = () => {
    this.props.closeModal();
  };
  handleGiven = () => {
    this.props.confirmGiven && this.props.confirmGiven();
  };

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>This Gift Was Given</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You sure you want to do that? You can't reverse this action, and it
            will be removed from any wish list or shopping list.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.handleGiven}>
              I know what I'm doing!
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default WarnGivenModal;
