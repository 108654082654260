import { FETCH_USERS_SUCCESS, LOGOUT } from "../actions/actions";

const initialState = [];

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return action.payload.users;
    case LOGOUT:
      return [];

    //DEFAULT
    default:
      return state;
  }
}

export default usersReducer;
