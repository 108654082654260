import React from "react";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import { withAuthorization } from "../Session";

const HomePage = () => (
  <div>
    <ol className="breadcrumb custom-breadcrumb">
      <li className="breadcrumb-item active" aria-current="page">
        Home
      </li>
    </ol>

    <div className="col-md-6 dashboard-button-container">
      <Link
        to={ROUTES.WISHLIST}
        className="btn btn-outline-danger col-sm-12 dashboard-button"
      >
        <h4 className="alert-heading">Wishlist</h4>
        <p>Clicking on this will take you to your list</p>
      </Link>
    </div>
    <div className="col-md-6 dashboard-button-container">
      <Link
        to={ROUTES.SHOPPING_CART}
        className="btn btn-outline-success col-sm-12 dashboard-button"
      >
        <h4 className="alert-heading">To Buy</h4>
        <p>This is the list of stuff you're buying for people</p>
      </Link>
    </div>
    <div className="col-md-6 dashboard-button-container">
      <Link
        to={ROUTES.PEOPLE}
        className="btn btn-outline-info col-sm-12 dashboard-button"
      >
        <h4 className="alert-heading">My People</h4>
        <p>These are the people that you can see the lists for</p>
      </Link>
    </div>
  </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);
