import React from "react";

const CheckMark = () => (
  <svg height="100%" width="100%" viewBox="0 0 100 100">
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke="green"
      strokeWidth="8"
      fill="transparent"
    />
    <line
      x1="25"
      y1="45"
      x2="53"
      y2="73"
      style={{ stroke: "green", strokeWidth: 10 }}
    />
    <line
      x1="50"
      y1="70"
      x2="85"
      y2="10"
      style={{ stroke: "green", strokeWidth: 10 }}
    />
  </svg>
);

export default CheckMark;
