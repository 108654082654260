import React from "react";

const Edit = () => (
  <svg height="100%" width="100%" viewBox="0 0 100 100">
    <polygon
      points="25,65 25,77.5 37.5,77.5 75,40 62.5,27.5"
      style={{ fill: "black", stroke: "black", strokeWidth: 1 }}
    />
    <polygon
      points="72,40 66.5,27.5 79,15 85.5,27.5"
      style={{ fill: "transparent", stroke: "black", strokeWidth: 6 }}
    />
  </svg>
);

export default Edit;
