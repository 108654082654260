import React from "react";

const Circle = () => (
  <svg height="100%" width="100%" viewBox="0 0 100 100">
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke="darkgray"
      strokeWidth="8"
      fill="transparent"
    />
  </svg>
);

export default Circle;
