import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDe3-QeNbPj42Y-8EGL4WJdo_h6HPQvK8M",
  authDomain: "loaereactdemo.firebaseapp.com",
  databaseURL: "https://loaereactdemo.firebaseio.com",
  projectId: "loaereactdemo",
  storageBucket: "",
  messagingSenderId: "260795805826",
  appId: "1:260795805826:web:9bfc95f963ffda28"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.database();
    this.firestore = app.firestore();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** User API ***

  user = uid => {
    //only called for signup.  TODO: move signup logic to redux.
    return this.db.ref(`users/${uid}`);
  };
}

export default Firebase;
