import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class ConnectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connection: null,
      searchResults: [],
      users: []
    };
  }

  getUsers = (users, connections) => {
    if (connections) {
      return users.filter(user => {
        return !connections[user.uid] && user.uid !== this.props.user.uid;
      });
    } else {
      return users;
    }
  };

  handleClose = () => {
    this.props.closeModal();
  };
  handleSave = () => {
    this.props.saveModal(this.state.connection);
  };

  handleSelectChange = e => {
    this.setState({
      connection: e.target.value
    });
  };

  onEmailChange = e => {
    const users = this.getUsers(this.props.users, this.props.connections);
    if (users.length === 0) {
      this.setState({
        noFurtherConnections: true,
        connection: null
      });
    } else {
      var searchResults = [];
      if (users && e.target.value) {
        for (var i = 0; i < users.length; i++) {
          if (users[i].email && users[i].email.indexOf(e.target.value) !== -1) {
            searchResults.push(users[i]);
          }
        }
        this.setState({
          email: e.target.value,
          searchResults: searchResults,
          connection: searchResults.length ? searchResults[0].uid : null,
          noFurtherConnections: false
        });
      } else {
        this.setState({
          email: e.target.value,
          searchResults: [],
          connection: null,
          noFurtherConnections: false
        });
      }
    }
  };

  render() {
    const { email } = this.state;
    const disabledSave = this.state.connection ? false : true;
    return (
      <>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Search for a new connection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group row">
                <label htmlFor="email" className="col-sm-2 col-form-label">
                  Search Email
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    value={email || ""}
                    onChange={this.onEmailChange}
                  />
                </div>
              </div>
              {this.state.searchResults.length ? (
                <select
                  className="custom-select"
                  value={this.state.connection}
                  onChange={this.handleSelectChange}
                >
                  {this.state.searchResults.map(user => (
                    <option value={user.uid} key={user.uid}>
                      {user.email}
                    </option>
                  ))}
                </select>
              ) : this.state.email ? (
                "No account found.  Try searching something else."
              ) : (
                ""
              )}
              {this.state.noFurtherConnections && (
                <span>
                  It looks like there are no more connections you can make.
                </span>
              )}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.handleSave}
              disabled={disabledSave}
            >
              Save Connection
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default ConnectionModal;
